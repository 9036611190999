import { Card, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import WidgetCard from "./dashComponents/WidgetCard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SellIcon from "@mui/icons-material/Sell";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { getAllTransactions } from "../../services/transactions";
import { getAllUsers } from "../../services/user";

const DashboardAdmin = () => {
  const [transactions, setTransactions] = useState([]);
  const [user, setUser] = useState([]);
  useEffect(() => {
    getUsers();
    getTransactions();
  }, []);
  const getUsers = () => {
    getAllUsers().then((res) => {
      if (res && res.data) {
        setUser(res.data.object);
      }
    });
  };

  const getTransactions = () => {
    getAllTransactions().then((res) => {
      if (res && res.data) {
        setTransactions(res.data.object);
      }
    });
  };
  return (
    <Container maxWidth="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <WidgetCard
            title="Users"
            icon={<PersonIcon fontSize="large" />}
            content={user.length}
            color={"#ddffcc"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <WidgetCard
            title="Transactions"
            icon={<CalendarMonthIcon fontSize="large" />}
            content={transactions.length}
            color={"#ddffcc"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <WidgetCard
            title="Device"
            icon={<SellIcon fontSize="large" />}
            content={5}
            color={"#ddffcc"}
          />
        </Grid>
      </Grid>
      <Card sx={{ padding: "20px", mt: 3 }}>
        {/* <Typography variant="h4" sx={{ mb: 2 }} align="center">
                Calendar
              </Typography> */}
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "today prev,next",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          height="90vh"
        />
      </Card>
    </Container>
  );
};

export default DashboardAdmin;
