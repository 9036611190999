import http from "./http";

export function getAllTransactions() {
  return http.get("/sensor/all");
}

export function getTransactionById(id) {
  return http.get(`/sensor/${id}`);
}

export function addTransaction(data) {
  return http.post("/sensor/add", data);
}

export function updateTransaction(id, form) {
  return http.patch(`/sensor/edit/${id}`, form);
}

export function assignUserToTransaction(userId, id) {
  return http.patch(`/sensor/assign/${userId}/${id}`);
}

export function deleteRecord(id) {
  return http.delete(`/sensor/delete/${id}`);
}
