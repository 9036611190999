import {
  Avatar,
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  Container,
} from "@mui/material";
import React, { useState } from "react";
// import { CheckBox } from "@mui/icons-material";
import { Copyright } from "../../components/Copyright";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { login } from "../../services/auth";
import { toast } from "react-toastify";
import logo from "../../assets/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    username: Joi.string().required(),
    password: Joi.string().required(),
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    const { error } = schema
      .extract(e.target.name)
      .label(e.target.name.toUpperCase())
      .validate(e.target.value);

    if (error) {
      setErrors({
        ...errors,
        [e.target.name]: error.details[0].message,
      });
    } else {
      delete errors[e.target.name];
      setErrors(errors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(form);
      console.log(form);
      if (res.data && res.data.status === 1) {
        localStorage.setItem("icheckvital", JSON.stringify(res.data.object));
        toast.success(res.data.message);
        navigate("/");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: 120, height: 120 }}
          alt="logo-lib"
          src={logo}
        />

        <Typography component="h1" variant="h5" fontWeight="bold">
          iCheckVital
        </Typography>
        <Typography component="h1" variant="h6" fontWeight="bold">
          LOGIN
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            name="username"
            label="Username"
            value={form.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={form.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link href="/registratrion" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
};

export default Login;
