import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import image1 from "../../assets/christian mulleno.jpg";
import image2 from "../../assets/John Edrian Floralde.png";
import image3 from "../../assets/dominic valenzuela.jpg";
import image4 from "../../assets/ronn angelo dictado.jpg";
import hero from "../../assets/hero.png";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import imagebg from "../../assets/bldg.jpg";

const AboutUs = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  console.log(image2);
  return (
    <Box
      maxWidth={{ sm: 720, md: 1236 }}
      width={"100%"}
      margin={"0 auto"}
      paddingX={2}
      paddingY={{ xs: 4, sm: 6, md: 8 }}
    >
      <Grid container spacing={4}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box data-aos={isMd ? "fade-right" : "fade-up"}>
            <Box marginBottom={2}>
              <Typography
                variant="h2"
                color="textPrimary"
                sx={{
                  fontWeight: 700,
                }}
              >
                About{" "}
                <Typography
                  color={"primary"}
                  component={"span"}
                  variant={"inherit"}
                >
                  us
                </Typography>
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="textSecondary">
                Our mission is to revolutionize healthcare by providing
                cutting-edge technology and secure vital signs monitoring. Join
                us in shaping a healthier and more secure future by trusting
                that your vital signs are accurately measured and transmitted to
                a blockchain network that guarantees the privacy and integrity
                of your data.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
            data-aos={isMd ? "fade-left" : "fade-up"}
          >
            <Box
              component={LazyLoadImage}
              height={"100%"}
              width={"200%"}
              src={hero}
              alt="..."
              effect="blur"
              boxShadow={3}
              borderRadius={4}
              maxWidth={600}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box marginTop={2} marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={"h3"}
          align={"center"}
        >
          Developers
        </Box>

        <Grid container spacing={2}>
          {[
            {
              name: "Christian Mulleno",
              title: "Project Manager",
              avatar: image1,
            },
            {
              name: "Dominic Valenzuela",
              title: "Integration&Testing",
              avatar: image3,
            },
            {
              name: "John Edrian Floralde",
              title: "Technical Manager",
              avatar: image2,
            },
            {
              name: "Ronn Angelo Dictado",
              title: "Application Developer",
              avatar: image4,
            },
          ].map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i} data-aos={"fade-up"}>
              <Box component={Card} boxShadow={0} bgcolor={"transparent"}>
                <Box
                  component={CardMedia}
                  borderRadius={2}
                  width={"100%"}
                  height={"100%"}
                  minHeight={320}
                  image={item.avatar}
                />
                <Box
                  component={CardContent}
                  bgcolor={"transparent"}
                  marginTop={-5}
                >
                  <Box component={Card} borderRadius={2}>
                    <CardContent>
                      <ListItemText
                        primary={item.name}
                        secondary={item.title}
                      />
                    </CardContent>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box
              display={"flex"}
              component="a"
              underline="none"
              href="/"
              title="webbee"
              height={24}
              width={35}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box marginBottom={4}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "medium",
                }}
                gutterBottom
                color={"secondary"}
              >
                Services
              </Typography>
            </Box>
            <Grid container spacing={4}>
              {[
                {
                  title: "Vital checks",
                  features: [
                    "Body Temperature",
                    "Pulse Rate",
                    "Oxygen Saturation",
                    "Blood Pressure",
                    "Blood Sugar Level",
                  ],
                },
                {
                  title: "Data security",
                  features: ["Database Storage", "Blockchain Security"],
                },
                {
                  title: "Other features",
                  features: [
                    "Patient Register",
                    "Vitals Monitoring",
                    "Vital Graph Charts",
                    "Prints CV",
                  ],
                },
              ].map((item, i) => (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Typography variant={"h6"} gutterBottom>
                    {item.title}
                  </Typography>
                  {item.features.map((feature, j) => (
                    <Box
                      component={ListItem}
                      disableGutters
                      width={"auto"}
                      padding={0}
                      key={j}
                      data-aos={"fade-up"}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={"auto !important"}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.primary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={feature} />
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography
            align={"center"}
            variant={"subtitle2"}
            color="textSecondary"
            gutterBottom
          >
            &copy; iCheckVital. 2023. All rights reserved
          </Typography>
          <Typography
            align={"center"}
            variant={"caption"}
            color="textSecondary"
            component={"p"}
          >
            When you visit or interact with our sites, services or tools, we or
            our authorised service providers may use cookies for storing
            information to help provide you with a better, faster and safer
            experience and for marketing purposes.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
