export const drawerWidth = 240;

export const title = "iCheckVital";

export const formatDate = (dateTimeString) => {
  return dateTimeString.split("T")[0];
};

export const formatTime = (dateTimeString) => {
  const timeString = dateTimeString.split("T")[1];
  const [hours, minutes] = timeString.split(":");
  const meridian = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${formattedHours}:${minutes} ${meridian}`;
};
