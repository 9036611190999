import React, { useState } from "react";
import {
  Avatar,
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import logo from "../../assets/logo.png";
import Joi from "joi";
import TextFieldSelect from "../../components/Select/TextFieldSelect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { register } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    birthdate: "",
    contactNumber: "",
    email: "",
    completeAddress: "",
    username: "",
    password: "",
    role: "user",
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      register(form).then((res) => {
        if (res && res.data.status === 1) {
          toast.success(res.data.message);
          navigate("/login");
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    firstName: Joi.string().required(),
    middleName: Joi.any().optional(),
    lastName: Joi.string().required(),
    gender: Joi.string().required(),
    birthdate: Joi.string().required(),
    contactNumber: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    completeAddress: Joi.string().required(),
    username: Joi.string().required(),
    password: Joi.string().required(),
    role: Joi.string().required(),
  });

  const handleChange = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    const { error } = schema
      .extract(e.target.name)
      .label(e.target.name.toUpperCase())
      .validate(e.target.value);

    if (error) {
      setErrors({
        ...errors,
        [e.target.name]: error.details[0].message,
      });
    } else {
      delete errors[e.target.name];
      setErrors(errors);
    }
  };

  const isFormInvalid = () => {
    const result = schema.validate(form);
    return !!result.error;
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: 120, height: 120 }}
          alt="logo-lib"
          src={logo}
        />
        <Typography component="h1" variant="h5" fontWeight="bold">
          iCheckVital
        </Typography>
        <Typography component="h1" variant="h6" fontWeight="bold">
          Register Here
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={form.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Middle Name"
                name="middleName"
                value={form.middleName}
                onChange={handleChange}
                error={!!errors.middleName}
                helperText={errors.middleName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={form.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Complete Address"
                name="completeAddress"
                value={form.completeAddress}
                onChange={handleChange}
                error={!!errors.completeAddress}
                helperText={errors.completeAddress}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Birthdate"
                name="birthdate"
                value={form.birthdate}
                onChange={handleChange}
                error={!!errors.birthdate}
                helperText={errors.birthdate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSelect
                label="Gender"
                name="gender"
                data={["Male", "Female"]}
                value={form.gender}
                onChange={handleChange}
                error={!!errors.gender}
                helperText={errors.gender}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Contact Number"
                name="contactNumber"
                value={form.contactNumber}
                onChange={handleChange}
                error={!!errors.contactNumber}
                helperText={errors.contactNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+63</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={form.username}
                onChange={handleChange}
                error={!!errors.username}
                helperText={errors.username}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                value={form.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isFormInvalid()}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
