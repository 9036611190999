import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import SuspenseFallback from "./components/SuspenseFallback";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7EA3CC",
      main: "#3C6EBF",
      dark: "#224D8F",
      contrastText: "#FFFFFF",
    },
  },
  secondary: {
    light: "#FF8A80",
    main: "#FF5252",
    dark: "#D50000",
    contrastText: "#FFFFFF",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<SuspenseFallback />}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>
);
