import http from "./http";

export function getUserById(id) {
  return http.get(`/user/${id}`);
}

export function getAllUsers() {
  return http.get("/user/all");
}

export function updateUser(id, data) {
  return http.patch(`/user/edit/${id}`, data);
}

export function activateUser(id, data) {
  return http.patch(`/user/activate/${id}`, data);
}

export function modifyRole(id, data) {
  return http.patch(`/user/modifyrole/${id}`, data);
}

export function getUserTransactionById(id) {
  return http.get(`/sensor/user/${id}`);
}

export function deleteUser(id) {
  return http.delete(`/user/delete/${id}`);
}

export function changePass(id, data) {
  return http.patch(`/user/security/${id}`, data);
}

export function getGeneratedID(id) {
  return http.get(`/user/generate-id-card/${id}`);
}

export function tito(id) {
  return http.post(`/user/tito/${id}`);
}
