import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Button,
  IconButton,
  Popover,
  Typography,
  Box,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { getCurrentUser, logout } from "../services/auth";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";

const IconPopover = ({ anchorEl, handleClose, handleClick }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/landing");
  };

  const user = getCurrentUser();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 15,
    height: 15,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  return (
    <>
      {/* <Typography fontWeight="bold" mr={1}>
        Hi, {user.firstName} {user.lastName}!
      </Typography> */}
      {/* <IconButton color="inherit" aria-describedby={id} onClick={handleLogout}>
        <LogoutIcon />
      </IconButton> */}
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <SmallAvatar>
                  <ArrowDropDownIcon color="black" />
                </SmallAvatar>
              }
            >
              <Avatar alt={user.firstName} src={user.imageURL} />{" "}
            </Badge>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={() => handleLogout()}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default IconPopover;
