import React from "react";

import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getCurrentUser } from "../services/auth";
import { adminRoutes, userRoutes } from "../routes";

const NavbarButton = () => {
  const user = getCurrentUser();

  const routes = user.role === "Admin" ? adminRoutes : userRoutes;

  return (
    <>
      {routes
        .filter((item) => {
          return (
            typeof item.icon !== "undefined" || typeof item.text !== "undefined"
          );
        })
        .map((item, index) => (
          <ListItemButton key={index} href={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
    </>
  );
};

export default NavbarButton;
