import React, { useState } from "react";
import { CssBaseline } from "@mui/material";
import LandingApprBar from "./landingcomponents/LandingApprBar";

import AboutUs from "./AboutUs";
import Clock from "./Clock";

const LandingPage = () => {
  const [page, setPage] = useState(0);

  const renderPage = () => {
    if (page === 0) {
      return <AboutUs />;
    }
    if (page === 1) {
      return <AboutUs />;
    }
  };

  return (
    <>
      <CssBaseline />
      <LandingApprBar setPage={setPage} />
      {renderPage()}
    </>
  );
};

export default LandingPage;
