import React, { lazy } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Dashboard } from "./pages/dashboard/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { Navigate, useRoutes } from "react-router";
import { getCurrentUser } from "./services/auth";
import DashboardContent from "./components/DashboardContent";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import LandingPage from "./pages/landing/LandingPage";

const Transactions = lazy(() => import("./pages/transaction/Transaction"));
const TransactionsAdd = lazy(() =>
  import("./pages/transaction/TransactionsAdd")
);
const TransactionsEdit = lazy(() =>
  import("./pages/transaction/TransactionsEdit")
);
const Profile = lazy(() => import("./pages/profile/Profile"));
const Users = lazy(() => import("./pages/users/Users"));
const ViewUsers = lazy(() => import("./pages/users/ViewUsers"));
const CPass = lazy(() => import("./pages/users/ChangePass"));

// Users
const UserProfile = lazy(() => import("./pages/userPages/profile/Profile"));
const UserTransactions = lazy(() =>
  import("./pages/userPages/transactions/Transaction")
);

export const adminRoutes = [
  { element: <Navigate to="/dashboard" />, index: true },
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    path: "/dashboard",
    // Do not lazy load the Dashboard
    element: <Dashboard />,
  },
  {
    icon: <PersonIcon />,
    text: "My Profile",
    path: "/profile",
    element: <Profile />,
  },
  {
    icon: <PersonIcon />,
    text: "Transactions",
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/transactions/add",
    element: <TransactionsAdd />,
  },
  {
    path: "/transactions/edit/:id",
    element: <TransactionsEdit />,
  },
  {
    icon: <GroupsIcon />,
    text: "Users",
    path: "/users",
    element: <Users />,
  },
  {
    path: "/users/:id",
    element: <ViewUsers />,
  },
  {
    path: "/changePass/:id",
    element: <CPass />,
  },
];

export const userRoutes = [
  { element: <Navigate to="/dashboard" />, index: true },
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    path: "/dashboard",
    // Do not lazy load the Dashboard
    element: <Dashboard />,
  },
  {
    icon: <PersonIcon />,
    text: "My Profile",
    path: "/profile",
    element: <UserProfile />,
  },
  {
    icon: <PersonIcon />,
    text: "Transactions",
    path: "/transactions",
    element: <UserTransactions />,
  },
];

export default function Router() {
  const getRoutes = () => {
    if (getCurrentUser() !== null) {
      if (getCurrentUser().role === "Admin") {
        return adminRoutes;
      } else if (getCurrentUser().role === "user") {
        return userRoutes;
      }
    }
  };
  const routes = useRoutes([
    {
      path: "/",
      element: getCurrentUser() ? (
        <DashboardContent />
      ) : (
        <Navigate to="/landing" />
      ),
      children: getRoutes(),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/registratrion",
      element: <Register />,
    },
    {
      path: "/landing",
      element: <LandingPage />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  return routes;
}
